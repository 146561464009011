exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-about-jsx": () => import("./../../../src/templates/About.jsx" /* webpackChunkName: "component---src-templates-about-jsx" */),
  "component---src-templates-catalogs-jsx": () => import("./../../../src/templates/Catalogs.jsx" /* webpackChunkName: "component---src-templates-catalogs-jsx" */),
  "component---src-templates-contact-jsx": () => import("./../../../src/templates/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/Home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-impress-jsx": () => import("./../../../src/templates/Impress.jsx" /* webpackChunkName: "component---src-templates-impress-jsx" */),
  "component---src-templates-landing-jsx": () => import("./../../../src/templates/Landing.jsx" /* webpackChunkName: "component---src-templates-landing-jsx" */),
  "component---src-templates-privacy-jsx": () => import("./../../../src/templates/Privacy.jsx" /* webpackChunkName: "component---src-templates-privacy-jsx" */),
  "component---src-templates-service-root-jsx": () => import("./../../../src/templates/ServiceRoot.jsx" /* webpackChunkName: "component---src-templates-service-root-jsx" */),
  "component---src-templates-services-jsx": () => import("./../../../src/templates/Services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */),
  "component---src-templates-terms-jsx": () => import("./../../../src/templates/Terms.jsx" /* webpackChunkName: "component---src-templates-terms-jsx" */)
}

